<template>
  <div class="admin-create">
    <div class="admin-navbar align-left">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
    </div>
    <h4 class="create-title">{{ $lang.app.creation }}</h4>
    <div class="admin-form-wrapper">
      <div class="admin-form">
        <Form @submit="submit">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.category }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field as="select" name="type"
                     v-model="createForm.type">
                <option v-for="type in helper.types" :key="type.value"
                        :value="type.value">{{ type.label }}</option>
              </Field>
            </div>
            <ErrorMessage name="type" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.question }} ({{ $lang.app.ru }}) <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="titleRu" type="text"
                     v-model="createForm.titleRu"/>
            </div>
            <ErrorMessage name="titleRu" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.question }} ({{ $lang.app.kg }}) <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="titleKg" type="text"
                     v-model="createForm.titleKg"/>
            </div>
            <ErrorMessage name="titleKg" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.answer }} ({{ $lang.app.ru }}) <i>*</i></label>
            <VueEditor v-model="createForm.textRu" />
            <ErrorMessage name="textRu" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.answer }} ({{ $lang.app.kg }}) <i>*</i></label>
            <VueEditor v-model="createForm.textKg" />
            <ErrorMessage name="textKg" class="recovery-label-error" />
          </div>

          <div class="reg-step-btn">
            <button type="submit">{{ $lang.app.create }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import { VueEditor } from "vue3-editor";
export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
  },
  data() {
    return {
      createForm: {
        type: null,
        titleRu: '',
        titleKg: '',
        textRu: '',
        textKg: '',
      },
      helper: {
        types: [
          {
            value: 'SCHOOL',
            label: this.$lang.app.school,
          },
          {
            value: 'PRESCHOOL',
            label: this.$lang.app.preschool,
          }
        ]
      }
    }
  },
  methods: {
    submit(values, actions) {
      this.$axios.post('/qna', this.createForm).then(() => {
        this.$snotify.success(this.$lang.app.resource_created_successfully);
        this.$router.push(`/admin/faq`)
      }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors(error.response.data.errors);
        }
      })
    },
  },
}
</script>
<style>
@import '../../../assets/styles/admin/create.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
</style>